<template>
  <a-modal
    :title="title"
    width="50%"
    destroyOnClose
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <a-spin :spinning="confirmLoading">
        <a-form-model
          ref="ruleForm"
          :model="model"
          :rules="validatorRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            prop="deptName"
            label="目录名称"
          >
            <a-input
              id="deptName"
              v-model="model.deptName"
              placeholder="请输入机构/部门名称"
            />
          </a-form-model-item>

          <a-form-model-item
            v-show="model.parentId!==undefined"
            prop="parentName"
            label="父模块"
          >
            <a-input
              id="parentName"
              placeholder=""
              v-model="parentName"
              :disabled="true"
            />
          </a-form-model-item>
          <a-form-model-item
            label="所属模块"
            prop="deptTypeSel"
          >
            <a-select
              placeholder="请选择所属模块"
              v-model="model.fileBusinessModular"
            >
              <a-select-option
                v-for="(item, key) in deptTypeSel"
                :key="key"
                :value="item.value"
              >{{ item.text}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea
              placeholder="请输入备注"
              v-model="model.remark"
              v-decorator="['remark', {}]"
            />
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>

import api from './api'

export default {
  name: 'SysDepartModal',
  data () {
    return {
      deptTypeSel: [],
      departTree: [],
      title: '操作',
      seen: false,
      visible: false,
      condition: true,
      disableSubmit: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      confirmLoading: false,
      validatorRules: {
        deptName: [{ required: true, message: '请输入机构/部门名称!' }],
        mobile: [{ validator: this.validateMobile }]
      },
      parentName: ''
    }
  },
  watch: {
    $route: {
      handler (n) {
        this.initDictData()
      },
      immediate: true,
      deep: true
    }
  },
  create () {
    this.finitDictData()
  },
  methods: {
    initDictData () {
      // 根据字典Code, 初始化字典数组
      api.ajaxGetDictItems({ dicKind: 'file_business_modular' }).then((res) => {
        if (res.status === 200) {
          this.deptTypeSel = res.data.map(item => { return { value: item.dicCode, text: item.dicDesc } })
        }
      })
    },
    handleChange (value) {
      this.model.deptType = value
    },
    loadTree () {
      this.$nextTick(() => {
        api.queryDepartTreeList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
        })
      })
    },
    finitDictData () {
      // 根据字典Code, 初始化字典数组
      api.ajaxGetDictItems({ dicKind: 'dept_type' }).then((res) => {
        if (res.status === 200) {
          this.deptTypeSel = res.data.map(res => { return { deptType: res.dicCode, text: res.dicDesc } })
        }
      })
    },
    add (depart, type) {
      if (depart !== undefined) {
        this.parentName = depart.nodeName
      }

      if (depart) {
        this.seen = false
      } else {
        this.seen = true
      }
      this.edit(depart, type)
    },
    async edit (record, type) {
      this.visible = true
      if (record !== undefined) {
        this.model = Object.assign({}, {})
        this.model.parentId = record.id
        this.model.deptType = ''

        this.model.deptLevel = ''
        this.model.remark = ''
        this.model.status = ''
        this.parentName = record.nodeName
      } else {
        this.model = Object.assign({}, {})
        this.model.deptType = ''

        this.model.deptLevel = ''
        this.model.remark = ''
        this.model.status = ''
      }
    },
    close () {
      this.$emit('close')
      this.disableSubmit = false
      this.visible = false
    },
    refresh () {
      this.loading = true
      this.loadTree()
    },
    handleOk () {
      const that = this
      // 触发表单验证
      this.$refs.ruleForm.validate((err, values) => {
        if (err) {
          that.confirmLoading = true
          this.model.nodeName = this.model.deptName
          if (this.model.parentId !== undefined) {
            api.fileClassAdd(this.model).then((res) => {
              this.model = {}
              that.$message.success(res.message)
              this.refresh()
            }).finally(() => {
              this.confirmLoading = false
              this.close()
            })
          } else {
            api.fileClassAdd(this.model).then((res) => {
              that.$message.success(res.message)
              this.model = {}
              this.refresh()
              that.$emit('ok')
            }).finally(() => {
              that.confirmLoading = false
              that.close()
            })
          }
          that.$emit('refresh')
        }
      })
    },
    handleCancel () {
      this.close()
    }
  }
}
</script>

<style scoped lang="less">
.ant-modal-body {
  display: none;
}
</style>
