<template>
  <a-row
    :gutter="10"
    class="_page-content"
  >
    <a-col
      :md="12"
      :sm="24"
    >
      <a-card :bordered="false">
        <!-- 按钮操作区域 -->
        <div style="margin-left: 14px">
          <a-button
            @click="handleAdd(1)"
            type="primary"
            icon="plus-square"
          >
            添加父目录</a-button>&nbsp;
          <a-button
            @click="refresh"
            type="default"
            icon="reload"
            :loading="loading"
          >刷新</a-button>
        </div>
        <div style="background: #fff;padding-left:16px;height: 100%; margin-top: 5px">
          <a-alert
            type="info"
            :showIcon="true"
          >
            <div slot="message">
              当前选择：
              <span
                v-if="this.currSelected.nodeName"
                style="color:black"
              >{{ getCurrSelectedTitle() }}</span>
              <a
                v-if="this.currSelected.nodeName"
                style="margin-left: 10px;color:#1890ff"
                @click="onClearSelected"
              >取消选择</a>
            </div>
          </a-alert>
          <a-input-search
            @search="onSearch"
            style="width:100%;margin-top: 10px"
            placeholder="请输入目录名称"
          />
          <!-- 树-->
          <a-row>
            <template>
              <a-dropdown
                :trigger="[this.dropTrigger]"
                @visibleChange="dropStatus"
              >
                <span style="user-select: none">
                  <a-tree
                    v-if="!loading"
                    ref="tree2"
                    style="height: 50vh;overflow-x: hidden;overflow-y: auto;"
                    multiple
                    @select="onSelect"
                    @check="onCheck"
                    @rightClick="rightHandle"
                    :selectedKeys="selectedKeys"
                    :load-data="onLoadData"
                    :checkedKeys="checkedKeys"
                    :treeData="departTree"
                    :checkStrictly="true"
                    :expandedKeys="iExpandedKeys"
                    :replace-fields="{children:'children', key:'id', value: 'nodeName', title: 'nodeName'}"
                    :autoExpandParent="autoExpandParent"
                    @expand="onExpand"
                  />
                </span>
                <!--新增右键点击事件,和增加添加和删除功能-->
                <a-menu slot="overlay">
                  <a-menu-item
                    @click="handleAdd(3)"
                    key="1"
                  >添加</a-menu-item>
                  <a-menu-item
                    @click="handleDelete"
                    key="2"
                  >删除</a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-row>
        </div>
      </a-card>
    </a-col>
    <a-col
      :md="12"
      :sm="24"
    >
      <a-card
        title="编辑"
        :bordered="false"
        style="padding-top: 84px"
      >
        <a-form-model
          ref="ruleForm"
          :model="model"
          :rules="validatorRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            label="目录名称"
            prop="nodeName"
          >
            <a-input v-model="model.nodeName" />
          </a-form-model-item>
          <a-form-model-item
            label="上级目录"
            prop="parentName"
            v-show="isShow"
          >
            <a-input
              :disabled="true"
              v-model="model.parentName"
            />
          </a-form-model-item>
          <a-form-model-item
            label="所属模块"
            prop="deptTypeSel"
          >
            <a-select
              placeholder="请选择所属模块"
              v-model="model.fileBusinessModular"
            >
              <a-select-option
                v-for="(item, key) in deptTypeSel"
                :key="key"
                :value="item.value"
              >{{ item.text}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="备注信息"
            prop="remark"
          >
            <a-input v-model="model.remark" />
          </a-form-model-item>
        </a-form-model>
        <div
          class="anty-form-btn"
          style="float: right"
        >
          <a-button
            @click="emptyCurrForm"
            type="default"
            htmlType="button"
            icon="sync"
          >重置</a-button>&nbsp;
          <a-button
            @click="submitCurrForm"
            type="primary"
            htmlType="button"
            icon="form"
          >修改并保存</a-button>
        </div>
      </a-card>
    </a-col>
    <depart-modal
      ref="departModal"
      @ok="loadTree"
      @refresh="refresh"
    ></depart-modal>
  </a-row>
</template>
<script>
import DepartModal from './DepartModal'
import api from './api'
export default {
  name: 'dept',
  components: {
    DepartModal
  },
  data () {
    return {
      isShow: true,
      deptClassSel: [],
      deptLevelSel: [],
      deptTypeSel: [],
      iExpandedKeys: [],
      loading: false,
      autoExpandParent: true,
      treeData: [],
      departTree: [],
      departTreeCopy: [],
      rightClickSelectedKey: {},
      model: {},
      dropTrigger: '',
      checkedKeys: [],
      selectedKeys: [],
      currSelected: {},
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      validatorRules: {
        departName: { rules: [{ required: true, message: '请输入机构/部门名称!' }] },
        orgCode: { rules: [{ required: true, message: '请输入机构编码!' }] },
        mobile: { rules: [{ validator: this.validateMobile }] }
      },
      node2: {},
      url: {
        delete: '/upms/dept/del',
        edit: '/upms/dept/update',
        deleteBatch: '/sys/sysDepart/deleteBatch',
        exportXlsUrl: 'sys/sysDepart/exportXls'
      }
    }
  },

  methods: {
    loadData () {
      this.refresh()
      this.initDictData()
    },
    initDictData () {
      api.ajaxGetDictItems({ dicKind: 'file_business_modular' }).then((res) => {
        if (res.status === 200) {
          this.deptTypeSel = res.data.map(item => { return { value: item.dicCode, text: item.dicDesc } })
        }
      })
    },
    loadTree () {
      this.$nextTick(() => {
        api.getTree().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
          this.departTreeCopy = this.departTree
        })
      })
    },
    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          api.fileClassList({ parentId: treeNode.dataRef.id }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            } else {
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.departTree = [...this.departTree]
          resolve()
        }, 1000)
      })
    },
    refresh () {
      this.loading = true
      this.loadTree()
      this.model = {}
      this.checkedKeys = []
      this.currSelected = {}
      this.form.resetFields()
      this.selectedKeys = []
    },
    // 右键操作方法
    rightHandle (node) {
      this.dropTrigger = 'contextmenu'
      this.rightClickSelectedKey = node.node.dataRef
    },
    onExpand (expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 右键点击下拉框改变事件
    dropStatus (visible) {
      if (visible === false) {
        this.dropTrigger = ''
      }
    },
    batchDel: function () {
      if (this.checkedKeys.length <= 0) {
        this.$message.warning('请选择一个部门单位！')
      } else {
        var ids = ''
        for (var a = 0; a < this.checkedKeys.length; a++) {
          ids += this.checkedKeys[a] + ','
        }
        var that = this
        this.$confirm({
          title: '确认删除',
          content: '确定要删除所选中的 ' + this.checkedKeys.length + ' 条数据，以及子节点数据吗?',
          cancelText: '取消',
          okText: '确定',
          onOk: function () {
            api.deleteAction({ ids: ids }).then((res) => {
              that.$message.success(res.message)
              that.loadTree()
              that.onClearSelected()
            })
          }
        })
      }
    },
    onSearch (value) {
      const that = this
      if (value) {
        api.searchByKeywords({ keyWord: value }).then((res) => {
          that.departTree = []
          for (let i = 0; i < res.data.length; i++) {
            const temp = res.data[i]
            that.departTree.push(temp)
          }
        })
      } else {
        this.iExpandedKeys = []
        this.loading = true
        this.departTree = this.departTreeCopy
        this.loading = false
      }
    },
    async onCheck (checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked
    },
    onSelect (selectedKeys, e) {
      const record = e.node.dataRef
      this.currSelected = Object.assign({}, record)
      this.selectedKeys = [record.key]
      this.setValuesToForm(record)
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm (record) {
      this.$nextTick(() => {
        api.get(record.id).then(res => {
          this.model = res.data
        })
      })

      this.form.getFieldDecorator('fax', { initialValue: '' })
    },
    getCurrSelectedTitle () {
      return !this.currSelected.nodeName ? '' : this.currSelected.nodeName
    },
    onClearSelected () {
      this.checkedKeys = []
      this.currSelected = {}
      this.form.resetFields()
      this.selectedKeys = []
      this.refresh()
    },
    submitCurrForm () {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.currSelected.id) {
            this.$message.warning('请点击选择要修改部门!')
            return
          }
          const formData = this.model
          api.fileClassUpdate(formData).then((res) => {
            this.$message.success(res.message)
            this.refresh()
          })
        }
      })
    },
    emptyCurrForm () {
      this.refresh()
    },
    handleAdd (num) {
      if (num === 1) {
        this.$refs.departModal.add()
        this.$refs.departModal.initDictData()
        this.$refs.departModal.title = '新增'
      } else if (num === 2) {
        const key = this.currSelected.id
        if (!key) {
          this.$message.warning('请选择一个部门单位!')
          return false
        }
        this.$refs.departModal.add(this.currSelected, 'Son')
        this.$nextTick(() => {
          this.$refs.departModal.initDictData()
        })

        this.$refs.departModal.title = '新增'
      } else {
        this.$refs.departModal.add(this.rightClickSelectedKey, 'father')
        this.$refs.departModal.title = '新增'
      }
    },
    async handleDelete () {
      var that = this
      this.$confirm({
        title: '是否删除',
        content: '请确定要删除此目录吗(此操作不可逆)?',
        cancelText: '取消',
        okText: '确定',
        onOk: function () {
          api.fileClassDelete(that.rightClickSelectedKey.id).then(res => {
            if (res.status === 200) {
              that.$message.success(res.message)
              that.refresh()
              that.onClearSelected()
            }
          })
        }
      })
    }
  },
  created () {
    this.loadData()
  }

}
</script>
